<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";

import animationData from "@/components/widgets/uetqnvvg.json";
import Axios from "@/config/axios";

export default {
  page: {
    title: "Details d'une Commande",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      current_order: { products: [], address: {} },
      title: "Details d'une Commande",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Details d'une Commande",
          active: true,
        },
      ],
      defaultOptions: { animationData: animationData },
    };
  },
  async beforeRouteEnter(route) {
    let current_order = {};
    await Axios.get("/admin/orders/" + route.params.id).then(({ data }) => {
      current_order = data.data;
    });

    localStorage.setItem("@CURRENT_ORDER", JSON.stringify(current_order));
  },
  beforeMount() {
    this.current_order = JSON.parse(
      localStorage.getItem("@CURRENT_ORDER") || "{}"
    );
  },
  methods: {
    updateorder(status) {
      Axios.put(`/admin/orders/${this.current_order.id}`, {
        status,
      })
        .then(() => {
          alert("Modification enregistrée ");
        })
        .catch((er) => {
          console.log(er);
        });
    },
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-9">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center">
              <h5 class="card-title flex-grow-1 mb-0">
                Commande #{{ current_order.id }}
              </h5>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive table-card">
              <table
                class="table table-nowrap align-middle table-borderless mb-0"
              >
                <thead class="table-light text-muted">
                  <tr>
                    <th scope="col">Details de Produit</th>
                    <th scope="col">Prix de l'article</th>
                    <th scope="col" class="text-center">Quantité</th>
                    <th scope="col" class="text-end">Montant Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) of current_order.products"
                    :key="index"
                  >
                    <td>
                      <div class="d-flex">
                        <div
                          class="flex-shrink-0 avatar-md bg-light rounded p-1"
                        >
                          <img
                            :src="item.product.small_pictures[0].small"
                            alt=""
                            class="img-fluid d-block"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="fs-15">
                            <router-link
                              :to="'/product-details/' + item.product.id"
                              class="link-primary"
                              >{{ item.product.name }}</router-link
                            >
                          </h5>
                          <p class="text-muted mb-0">
                            Catégories:
                            <span class="fw-medium">{{
                              item.product.product_categories
                                .map((cat) => cat.name)
                                .join(", ")
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>{{ item.unit_price }} FCFA</td>
                    <td class="text-center">{{ item.items }}</td>
                    <td class="fw-medium text-end">
                      {{ item.total_price }} FCFA
                    </td>
                  </tr>
                  <tr class="border-top border-top-dashed">
                    <td colspan="3"></td>
                    <td colspan="2" class="fw-medium p-0">
                      <table class="table table-borderless mb-0">
                        <tbody>
                          <tr class="border-top border-top-dashed">
                            <th scope="row">Total (XAF) :</th>
                            <th class="text-end">
                              {{ current_order.amount }}
                              FCFA
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-->
        <div class="card">
          <div class="card-header">
            <div class="d-sm-flex align-items-center">
              <h5 class="card-title flex-grow-1 mb-0">Statut de la commande</h5>
              <div class="flex-shrink-0 mt-2 mt-sm-0">
                <a
                  href="javascript:void(0);"
                  @click.prevent="updateorder('DELIVERED')"
                  class="btn btn-soft-info btn-sm mt-2 mt-sm-0 shadow-none"
                  ><i class="ri-map-pin-line align-middle me-1"></i>
                  Livrer la commande
                </a>
                <a
                  href="javascript:void(0);"
                  class="btn btn-soft-danger btn-sm mt-2 mt-sm-0 ms-1 shadow-none"
                  @click.prevent="updateorder('CANCELLED')"
                  ><i
                    class="mdi mdi-archive-remove-outline align-middle me-1"
                  ></i>
                  Annuler la commande
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xl-3">
        <div class="card">
          <div class="card-header">
            <div class="d-flex">
              <h5 class="card-title flex-grow-1 mb-0">Details sur le client</h5>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0 vstack gap-3">
              <li>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="fs-14 mb-1">
                      {{ current_order.address.fullname }}
                    </h6>
                  </div>
                </div>
              </li>
              <li>
                <i class="ri-mail-line me-2 align-middle text-muted fs-16"></i
                >{{ current_order.address.email }}
              </li>
              <li>
                <i class="ri-phone-line me-2 align-middle text-muted fs-16"></i
                >{{ current_order.address.phone }}
              </li>
            </ul>
          </div>
        </div>

        <!--end card-->
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">
              <i class="ri-map-pin-line align-middle me-1 text-muted"></i>
              Adresse de livraison
            </h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled vstack gap-2 fs-13 mb-0">
              <li>Ville : {{ current_order.address.city }}</li>
              <li>Quartier : {{ current_order.address.quater }}</li>
              <li>Précisions : {{ current_order.address.address_details }}</li>
            </ul>
          </div>
        </div>
        <!--end card-->

        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
